const cpttRouter = {
  list: {
    path: '/cptt_list',
    name: 'CpttList',
    component: () => import('@/views/SystemManagement/CpttManage/CpttList.vue'),
    meta: {
      title: '公司信息列表'
    }
  },
  add: {
    path: '/cptt_add',
    name: 'CpttAdd',
    component: () => import('@/views/SystemManagement/CpttManage/CpttAdd.vue'),
    meta: {
      title: '公司信息（新增）'
    }
  },
  edit: {
    path: '/cptt_edit',
    name: 'CpttEdit',
    component: () => import('@/views/SystemManagement/CpttManage/CpttEdit.vue'),
    meta: {
      title: '公司信息（编辑）'
    }
  }
};
export default cpttRouter;
