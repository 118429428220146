import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import VueCoreVideoPlayer from 'vue-core-video-player';
import helper from './assets/js/helper.js';
import comApi from './assets/js/comApi.js';
import UrlEncode from './assets/js/UrlEncode.js';
import 'default-passive-events';
import websocket from 'vue-native-websocket';

import './plugins/element.js';
import './assets/css/global.scss';
import 'echarts';
import ECharts from 'vue-echarts';

Vue.component('VueEcharts', ECharts);

Vue.use(websocket, '', {
  connectManually: true, // 手动连接
  format: 'json', // json格式
  reconnection: true, // 是否自动重连
  reconnectionAttempts: 5, // 自动重连次数
  reconnectionDelay: 2000 // 重连间隔时间
});
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
});
Vue.prototype.$UrlEncode = UrlEncode;

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.prototype.helper = helper;
Vue.prototype.comApi = comApi;

Vue.prototype.$EventBus = new Vue();

Vue.prototype.jump = (p, q) => {
  router.push({ path: p, query: q }).catch(err => err);
};
// Vue.prototype.setCookie = function (cname,cvalue,exdays) {
//   let d = new Date();
//
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
//
//   let expires = "expires=" + d.toUTCString();
//
//   console.info(cname + "=" + cvalue + "; " + expires);
//
//   document.cookie = cname + "=" + cvalue + "; " + expires;
//
//   console.info(document.cookie);
// }
// this.$cookies.config(60 * 60 * 24,'') // 设置过期时间
router.beforeEach((to, from, next) => {
  let toJump = to;
  if (toJump.path === '/login') {
    if (toJump.fullPath.indexOf('?') !== -1) {
      let temp = JSON.parse(JSON.stringify(toJump.query));
      Vue.prototype.$cookies.set('isVrp', temp);
    }
  }
  if (Vue.prototype.$cookies.get('userInfo')) {
    next();
  } else {
    if (to.fullPath === '/login') {
      next();
    } else {
      next({ path: '/login' });
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
