const qutaRouter = {
  list: {
    path: '/quta_list',
    name: 'QutaList',
    component: () => import('@/views/DevelopManagement/QutaManage/QutaList.vue'),
    meta: {
      title: '样品报价单'
    }
  },
  edit: {
    path: '/quta_edit',
    name: 'QutaEdit',
    component: () => import('@/views/DevelopManagement/QutaManage/QutaEdit.vue'),
    meta: {
      title: '样品报价单 (编辑)'
    }
  }
};
export default qutaRouter;
