const mdrlRouter = {
  list: {
    path: '/mdrl_list',
    name: 'MdrlList',
    component: () => import('@/views/MessageManagement/MdrlManage/MdrlList.vue'),
    meta: {
      title: '模具物料信息 列表'
    }
  },
  add: {
    path: '/mdrl_add',
    name: 'MdrlAdd',
    component: () => import('@/views/MessageManagement/MdrlManage/MdrlAdd.vue'),
    meta: {
      title: '模具物料信息 新增'
    }
  },
  edit: {
    path: '/mdrl_edit',
    name: 'MdrlEdit',
    component: () => import('@/views/MessageManagement/MdrlManage/MdrlEdit.vue'),
    meta: {
      title: '模具物料信息 编辑'
    }
  },
  push: {
    path: '/mdrl_push_list_9002',
    name: 'MdrlPush9002',
    component: () => import('@/views/MessageManagement/MdrlManage/MdrlPush9002.vue'),
    meta: {
      title: '模具物料推送信息'
    }
  }
};
export default mdrlRouter;
