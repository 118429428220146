const qrcdRouter = {
  list: {
    path: '/qrcd_list',
    name: 'QrcdList',
    component: () => import('@/views/StockManagement/QrcdManage/QrcdList.vue'),
    meta: {
      title: '二维码信息 列表'
    }
  },
  add: {
    path: '/qrcd_add',
    name: 'QrcdAdd',
    component: () => import('@/views/StockManagement/QrcdManage/QrcdAdd.vue'),
    meta: {
      title: '二维码 新增'
    }
  },
  edit: {
    path: '/qrcd_edit',
    name: 'QrcdEdit',
    component: () => import('@/views/StockManagement/QrcdManage/TabChild/QrcdEdit.vue'),
    meta: {
      title: '二维码 新增'
    }
  }
};
export default qrcdRouter;
