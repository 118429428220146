const muopRouter = {
  read_list: {
    path: '/murf_list',
    name: 'ReadList',
    component: () => import('@/views/OperateManagement/MouldReadManage/Read.vue'),
    meta: {
      title: '模具读卡运营'
    }
  },
  muop_list: {
    path: '/muop_list',
    name: 'openList',
    component: () => import('@/views/OperateManagement/MouldReadManage/Open.vue'),
    meta: {
      title: '模具开卡运营'
    }
  },
  muop_add: {
    path: '/muop_add',
    name: 'openAdd',
    component: () => import('@/views/OperateManagement/MouldReadManage/TabChild/openAdd.vue'),
    meta: {
      title: '模具开卡运营新增'
    }
  },
  muop_edit: {
    path: '/muop_edit',
    name: 'openEdit',
    component: () => import('@/views/OperateManagement/MouldReadManage/TabChild/openEdit.vue'),
    meta: {
      title: '模具开卡运营修改'
    }
  },
  mupc_list: {
    path: '/mupc_list',
    name: 'mupcList',
    component: () => import('@/views/OperateManagement/MouldReadManage/ProcList.vue'),
    meta: {
      title: '模具工序分配'
    }
  },
  mupc_add: {
    path: '/mupc_add',
    name: 'mupcAdd',
    component: () => import('@/views/OperateManagement/MouldReadManage/TabChild/ProcAdd.vue'),
    meta: {
      title: '模具新增工序分配'
    }
  },
  mupc_edit: {
    path: '/mupc_edit',
    name: 'mupc_edit',
    component: () => import('@/views/OperateManagement/MouldReadManage/TabChild/ProcEdit.vue'),
    meta: {
      title: '模具修改工序分配'
    }
  }
};
export default muopRouter;
