const smplRouter = {
    list: {
        path: '/smpl_list',
        name: 'SmplList',
        component: () => import('@/views/DevelopManagement/SmplManage/SmplList.vue'),
        meta: {
            title: '样品总控表'
        }
    },
    edit: {
        path: '/smpl_edit',
        name: 'SmplEdit',
        component: () => import('@/views/DevelopManagement/SmplManage/SmplEdit.vue'),
        meta: {
            title: '样品工单'
        }
    },
    push: {
        path: '/mail_push_list_9003',
        name: 'MailPush9003',
        component: () => import('@/views/DevelopManagement/SmplManage/MailPush9003.vue'),
        meta: {
            title: '邮件推送信息'
        }
    },
    push1: {
        path: '/smpl_push_list_9006',
        name: 'SmplPush9006',
        component: () => import('@/views/DevelopManagement/SmplManage/SmplPush9006.vue'),
        meta: {
            title: '工艺信息完善提醒'
        }
    }
};
export default smplRouter;
