const npsmRouter = {
  list: {
    path: '/npsm_list',
    name: 'NpsmList',
    component: () => import('@/views/DevelopManagement/NpsmManage/NpsmList.vue'),
    meta: {
      title: '非毛绒类样品 列表'
    }
  },
  add: {
    path: '/npsm_add',
    name: 'NpsmAdd',
    component: () => import('@/views/DevelopManagement/NpsmManage/NpsmAdd.vue'),
    meta: {
      title: '非毛绒类样品 新增'
    }
  },
  edit: {
    path: '/npsm_edit',
    name: 'NpsmEdit',
    component: () => import('@/views/DevelopManagement/NpsmManage/NpsmEdit.vue'),
    meta: {
      title: '非毛绒类样品 编辑'
    }
  }
};
export default npsmRouter;
