const mmplRouter = {
  list: {
    path: '/mmpl_list',
    name: 'MmplList',
    component: () => import('@/views/MouldManagement/MmplManage/MmplList.vue'),
    meta: {
      title: '模具总控表 列表'
    }
  },
  /*  add: {
    path: '/mmpl_add',
    name: 'MmplAdd',
    component: () => import('@/views/MouldManagement/MmplManage/MmplAdd.vue'),
    meta: {
        title: '模具总控表 新增'
    },
},*/
  edit: {
    path: '/mmpl_edit',
    name: 'MmplEdit',
    component: () => import('@/views/MouldManagement/MmplManage/MmplEdit.vue'),
    meta: {
      title: '模具总控表 编辑'
    }
  }
};
export default mmplRouter;
