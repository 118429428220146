import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageSize: 10,
    reGetDashBoardData: false
  },
  mutations: {
    setDashboardData(state, data) {
      state.reGetDashBoardData = data;
    }
  },
  actions: {},
  modules: {}
});
