const apprRouter = {
  list: {
    path: '/appr_list',
    name: 'ApprList',
    component: () => import('@/views/SystemManagement/ApprManage/ApprList.vue'),
    meta: {
      title: '审批配置 列表'
    }
  },
  edit: {
    path: '/appr_edit',
    name: 'ApprEdit',
    component: () => import('@/views/SystemManagement/ApprManage/ApprEdit.vue'),
    meta: {
      title: '审批配置 编辑'
    }
  },
  toApproved: {
    path: 'toApproved_all',
    name: 'beApprovedAll',
    component: () => import('@/views/component/boardCommon/boardAllLeft.vue'),
    meta: {
      title: '需审批全部'
    }
  },
  beApproved: {
    path: 'beApproved_alll',
    name: 'beApprovedAlll',
    component: () => import('@/views/component/boardCommon/boardAllRight.vue'),
    meta: {
      title: '被审批全部'
    }
  }
};
export default apprRouter;
