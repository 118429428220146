const rqtaRouter = {
    list: {
        path: '/rqta_list',
        name: 'RqtaList',
        component: () => import('@/views/DevelopManagement/RqtaManage/RqtaList.vue'),
        meta: {
            title: '样品核价单'
        }
    },
    edit: {
        path: '/rqta_edit',
        name: 'RqtaEdit',
        component: () => import('@/views/DevelopManagement/RqtaManage/RqtaEdit.vue'),
        meta: {
            title: '样品核价单 (编辑)'
        }
    },
    push_9009: {
        path: '/push_9009',
        name: 'Push9009',
        component: () => import('@/views/DevelopManagement/RqtaManage/Push9009.vue'),
        meta: {
            title: '报价单退回提醒'
        }
    },
};
export default rqtaRouter;
