const moudRouter = {
  list: {
    path: '/moud_list',
    name: 'MoudList',
    component: () => import('@/views/PurchaseManagement/MoudManage/MoudList.vue'),
    meta: {
      title: '模具采购申请单 列表'
    }
  },
  add: {
    path: '/moud_add',
    name: 'MoudAdd',
    component: () => import('@/views/PurchaseManagement/MoudManage/MoudAdd.vue'),
    meta: {
      title: '模具采购申请单 新增'
    }
  },
  edit: {
    path: '/moud_edit',
    name: 'MoudEdit',
    component: () => import('@/views/PurchaseManagement/MoudManage/MoudEdit.vue'),
    meta: {
      title: '模具采购申请单 编辑'
    }
  }
};
export default moudRouter;
