import Vue from 'vue';
import VueRouter from 'vue-router';
/**
 * 重写路由的push方法
 * 解决NavigationDuplicated问题
 */
// const routerPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error => error)
// }
/*Router Modules*/
import cpttRouter from '@/router/modules/cpttRouter';
import deptRouter from '@/router/modules/deptRouter';
import stffRouter from '@/router/modules/stffRouter';
import roleRouter from '@/router/modules/roleRouter';
import permRouter from '@/router/modules/permRouter';
import userRouter from '@/router/modules/userRouter';
import apprRouter from '@/router/modules/apprRouter';
import optnRouter from '@/router/modules/optnRouter';
import custRouter from '@/router/modules/custRouter';
import suppRouter from '@/router/modules/suppRouter';
import mdrlRouter from '@/router/modules/mdrlRouter';
import mtrlRouter from '@/router/modules/mtrlRouter';
import requRouter from '@/router/modules/requRouter';
import smplRouter from '@/router/modules/smplRouter';
import sourRouter from '@/router/modules/sourRouter';
import qrcdRouter from '@/router/modules/qrcdRouter';
import mstfRouter from '@/router/modules/mstfRouter';
import mstkRouter from '@/router/modules/mstkRouter';
import operRouter from '@/router/modules/operRouter';
import colrRouter from '@/router/modules/colrRouter';
import qutaRouter from '@/router/modules/qutaRouter';
import rqtaRouter from '@/router/modules/rqtaRouter';
import settRouter from './modules/settRouter';
import starRouter from '@/router/modules/starRouter';
import npsmRouter from '@/router/modules/npsmRouter';
import mdinRouter from '@/router/modules/mdinRouter';
import mdopRouter from '@/router/modules/mdopRouter';
import mmplRouter from '@/router/modules/mmplRouter';
import mqcdRouter from '@/router/modules/mqcdRouter';
import mdtkRouter from '@/router/modules/mdtkRouter';
import mdtfRouter from '@/router/modules/mdtfRouter';
import moudRouter from '@/router/modules/moudRouter';
import muopRouter from '@/router/modules/muopRouter';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/',
        component: () => import('@/components/common/Home.vue'),
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/Dashboard.vue'),
                meta: {title: '系统首页'}
            },
            cpttRouter.list,
            cpttRouter.add,
            cpttRouter.edit,
            deptRouter.list,
            deptRouter.add,
            deptRouter.edit,
            stffRouter.list,
            stffRouter.add,
            stffRouter.edit,
            roleRouter.list,
            roleRouter.edit,
            permRouter.list,
            userRouter.list,
            userRouter.add,
            userRouter.edit,
            apprRouter.list,
            apprRouter.edit,
            apprRouter.toApproved,
            apprRouter.beApproved,
            optnRouter.list,
            optnRouter.edit,
            custRouter.list,
            custRouter.add,
            custRouter.edit,
            suppRouter.list,
            suppRouter.add,
            suppRouter.edit,
            mtrlRouter.list,
            mtrlRouter.add,
            mtrlRouter.edit,
            mtrlRouter.push,
            mdrlRouter.list,
            mdrlRouter.add,
            mdrlRouter.edit,
            mdrlRouter.push,
            requRouter.list,
            requRouter.add,
            requRouter.edit,
            requRouter.push,
            requRouter.push1,
            requRouter.push2,
            requRouter.push3,
            smplRouter.list,
            smplRouter.edit,
            smplRouter.push,
            smplRouter.push1,
            sourRouter.list,
            sourRouter.add,
            sourRouter.edit,
            qrcdRouter.list,
            qrcdRouter.add,
            qrcdRouter.edit,
            mqcdRouter.list,
            mqcdRouter.add,
            mstfRouter.list,
            mstfRouter.add,
            mstfRouter.addO,
            mdtfRouter.list,
            mdtfRouter.add,
            mdtfRouter.addO,
            mstkRouter.list,
            mstkRouter.push,
            mdtkRouter.list,
            mdtkRouter.push,
            operRouter.read_list,
            operRouter.open_list,
            operRouter.proc_list,
            operRouter.proc_add,
            operRouter.proc_edit,
            operRouter.open_add,
            operRouter.open_edit,
            muopRouter.read_list,
            muopRouter.muop_list,
            muopRouter.mupc_list,
            muopRouter.mupc_add,
            muopRouter.mupc_edit,
            muopRouter.muop_add,
            muopRouter.muop_edit,
            qutaRouter.list,
            qutaRouter.edit,
            rqtaRouter.list,
            rqtaRouter.edit,
            rqtaRouter.push_9009,
            colrRouter.list,
            starRouter.statList,
            starRouter.prstList,
            npsmRouter.list,
            npsmRouter.edit,
            npsmRouter.add,
            mdinRouter.list,
            mdinRouter.edit,
            mdinRouter.add,
            mdopRouter.list,
            mdopRouter.edit,
            mdopRouter.add,
            mmplRouter.list,
            mmplRouter.edit,
            moudRouter.list,
            moudRouter.add,
            moudRouter.edit,
            settRouter.list,
            {
                path: '/about',
                name: 'About',
                component: () => import('@/views/About.vue'),
                meta: {title: '关于'}
            }
        ]
    },
    {
        path: '/img_list',
        name: 'ImgList',
        component: () => import('@/views/StockManagement/QrcdManage/QrcdImg.vue'),
        meta: {title: '二维码打印'}
    },
    {
        path: '/login',
        name: 'Login',
        require: true,
        component: () => import('@/views/Login.vue'),
        meta: {title: '登录'}
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
