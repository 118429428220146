const starRouter = {
  statList: {
    path: '/stat_list',
    name: 'statList',
    component: () => import('@/views/DataStatistics/ProductValueYieldStatistics/ProductValueYieldStatistics.vue'),
    meta: {
      title: '产值产量统计'
    }
  },
  prstList: {
    path: '/prst_list',
    name: 'prstList',
    component: () => import('@/views/DataStatistics/ProductValueYieldStatistics/ProductionClassificationStatistics.vue'),
    meta: {
      title: '生产分类统计'
    }
  },
  edit: {
    path: '/user_edit',
    name: 'UserEdit',
    component: () => import('@/views/SystemManagement/UserManage/UserEdit.vue'),
    meta: {
      title: '账号信息 编辑'
    }
  }
};

export default starRouter;
