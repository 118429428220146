const mtrlRouter = {
  list: {
    path: '/mtrl_list',
    name: 'MtrlList',
    component: () => import('@/views/MessageManagement/MtrlManage/MtrlList.vue'),
    meta: {
      title: '物料信息 列表'
    }
  },
  add: {
    path: '/mtrl_add',
    name: 'MtrlAdd',
    component: () => import('@/views/MessageManagement/MtrlManage/MtrlAdd.vue'),
    meta: {
      title: '物料信息 新增'
    }
  },
  edit: {
    path: '/mtrl_edit',
    name: 'MtrlEdit',
    component: () => import('@/views/MessageManagement/MtrlManage/MtrlEdit.vue'),
    meta: {
      title: '物料信息 编辑'
    }
  },
  push: {
    path: '/mtrl_push_list_9002',
    name: 'MtrlPush9002',
    component: () => import('@/views/MessageManagement/MtrlManage/MtrlPush9002.vue'),
    meta: {
      title: '物料推送信息'
    }
  }
};
export default mtrlRouter;
