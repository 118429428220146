const mstkRouter = {
  list: {
    path: '/mstk_list',
    name: 'MstkList',
    component: () => import('@/views/StockManagement/MstkManage/MstkList.vue'),
    meta: {
      title: '物料总库存表 列表'
    }
  },
  push: {
    path: '/mstk_push_list_9001',
    name: 'MstkPush9001',
    component: () => import('@/views/StockManagement/MstkManage/MstkPush9001.vue'),
    meta: {
      title: '物料库存提醒'
    }
  }
};
export default mstkRouter;
