const mdtfRouter = {
  list: {
    path: '/mdtf_list',
    name: 'MdtfList',
    component: () => import('@/views/StockManagement/MdtfManage/MdtfList.vue'),
    meta: {
      title: '模具出入库 列表'
    }
  },
  add: {
    path: '/mdtf_add',
    name: 'MdtfAdd',
    component: () => import('@/views/StockManagement/MdtfManage/MdtfAdd.vue'),
    meta: {
      title: '模具入库 新增'
    }
  },
  addO: {
    path: '/mdtf_addO',
    name: 'MdtfAddO',
    component: () => import('@/views/StockManagement/MdtfManage/MdtfAddO.vue'),
    meta: {
      title: '模具出库 新增'
    }
  }
};
export default mdtfRouter;
