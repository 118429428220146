const stffRouter = {
  list: {
    path: '/stff_list',
    name: 'StffList',
    component: () => import('@/views/SystemManagement/StffManage/StffList.vue'),
    meta: {
      title: '员工信息列表'
    }
  },
  add: {
    path: '/stff_add',
    name: 'StffAdd',
    component: () => import('@/views/SystemManagement/StffManage/StffAdd.vue'),
    meta: {
      title: '员工信息（新增）'
    }
  },
  edit: {
    path: '/stff_edit',
    name: 'StffEdit',
    component: () => import('@/views/SystemManagement/StffManage/StffEdit.vue'),
    meta: {
      title: '员工信息（编辑）'
    }
  }
};
export default stffRouter;
