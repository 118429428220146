const mdopRouter = {
  list: {
    path: '/mdnc_list',
    name: 'MdopList',
    component: () => import('@/views/MouldManagement/MdopManage/MdopList.vue'),
    meta: {
      title: '模具开模通知单 列表'
    }
  },
  add: {
    path: '/mdnc_add',
    name: 'MdopAdd',
    component: () => import('@/views/MouldManagement/MdopManage/MdopAdd.vue'),
    meta: {
      title: '模具开模通知单 新增'
    }
  },
  edit: {
    path: '/mdnc_edit',
    name: 'MdopEdit',
    component: () => import('@/views/MouldManagement/MdopManage/MdopEdit.vue'),
    meta: {
      title: '模具开模通知单 编辑'
    }
  }
};
export default mdopRouter;
