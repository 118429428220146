const mqcdRouter = {
  list: {
    path: '/mqcd_list',
    name: 'MqcdList',
    component: () => import('@/views/StockManagement/MqcdManage/MqcdList.vue'),
    meta: {
      title: '模具二维码信息 列表'
    }
  },
  add: {
    path: '/mqcd_add',
    name: 'MqcdAdd',
    component: () => import('@/views/StockManagement/MqcdManage/MqcdAdd.vue'),
    meta: {
      title: '模具二维码 新增'
    }
  }
};
export default mqcdRouter;
