const sourRouter = {
  list: {
    path: '/sour_list',
    name: 'SourList',
    component: () => import('@/views/PurchaseManagement/SourManage/SourList.vue'),
    meta: {
      title: '采购申请单 列表'
    }
  },
  add: {
    path: '/sour_add',
    name: 'SourAdd',
    component: () => import('@/views/PurchaseManagement/SourManage/SourAdd.vue'),
    meta: {
      title: '采购申请单 新增'
    }
  },
  edit: {
    path: '/sour_edit',
    name: 'SourEdit',
    component: () => import('@/views/PurchaseManagement/SourManage/SourEdit.vue'),
    meta: {
      title: '采购申请单 编辑'
    }
  }
};
export default sourRouter;
