const mdinRouter = {
  list: {
    path: '/mdin_list',
    name: 'MdinList',
    component: () => import('@/views/MouldManagement/MdinManage/MdinList.vue'),
    meta: {
      title: '模具信息库 列表'
    }
  },
  add: {
    path: '/mdin_add',
    name: 'MdinAdd',
    component: () => import('@/views/MouldManagement/MdinManage/MdinAdd.vue'),
    meta: {
      title: '模具信息库 新增'
    }
  },
  edit: {
    path: '/mdin_edit',
    name: 'MdinEdit',
    component: () => import('@/views/MouldManagement/MdinManage/MdinEdit.vue'),
    meta: {
      title: '模具信息库 编辑'
    }
  }
};
export default mdinRouter;
