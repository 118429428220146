const requRouter = {
    list: {
        path: '/requ_list',
        name: 'RequList',
        component: () => import('@/views/DevelopManagement/RequManage/RequList.vue'),
        meta: {
            title: '委托接收单 列表'
        }
    },
    add: {
        path: '/requ_add',
        name: 'RequAdd',
        component: () => import('@/views/DevelopManagement/RequManage/RequAdd.vue'),
        meta: {
            title: '委托接收单 新增'
        }
    },
    edit: {
        path: '/requ_edit',
        name: 'RequEdit',
        component: () => import('@/views/DevelopManagement/RequManage/RequEdit.vue'),
        meta: {
            title: '委托接收单 编辑'
        }
    },
    push: {
        path: '/requ_push_list_9004',
        name: 'RequPush9004',
        component: () => import('@/views/DevelopManagement/RequManage/RequPush9004.vue'),
        meta: {
            title: '委托接收单提醒'
        }
    },
    push1: {
        path: '/requ_push_list_9005',
        name: 'RequPush9005',
        component: () => import('@/views/DevelopManagement/RequManage/RequPush9005.vue'),
        meta: {
            title: '采购退样提醒'
        }
    },
    push2: {
        path: '/docu_push_list_9007',
        name: 'RequPush9007',
        component: () => import('@/views/DevelopManagement/RequManage/RequPush9007.vue'),
        meta: {
            title: '样品信息更新提醒'
        }
    },
    push3: {
        path: '/cancelsmpl_push_list_9008',
        name: 'RequPush9007',
        component: () => import('@/views/DevelopManagement/RequManage/RequPush9008.vue'),
        meta: {
            title: '取消打样提醒'
        }
    }
};
export default requRouter;
