const ColrRouter = {
  list: {
    path: '/colr_list',
    name: 'ColrList',
    component: () => import('@/views/SystemManagement/ColrManage/ColrList'),
    meta: {
      title: '大色分类 列表'
    }
  }
};

export default ColrRouter;
