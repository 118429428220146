const operRouter = {
  read_list: {
    path: '/read_list',
    name: 'ReadList',
    component: () => import('@/views/OperateManagement/ReadManage/Read.vue'),
    meta: {
      title: '读卡运营'
    }
  },
  open_list: {
    path: '/open_list',
    name: 'openList',
    component: () => import('@/views/OperateManagement/ReadManage/Open.vue'),
    meta: {
      title: '开卡运营'
    }
  },
  open_add: {
    path: '/open_add',
    name: 'openAdd',
    component: () => import('@/views/OperateManagement/ReadManage/TabChild/openAdd.vue'),
    meta: {
      title: '开卡运营新增'
    }
  },
  open_edit: {
    path: '/open_edit',
    name: 'openEdit',
    component: () => import('@/views/OperateManagement/ReadManage/TabChild/openEdit.vue'),
    meta: {
      title: '开卡运营修改'
    }
  },
  proc_list: {
    path: '/proc_list',
    name: 'procList',
    component: () => import('@/views/OperateManagement/ReadManage/ProcList.vue'),
    meta: {
      title: '工序分配'
    }
  },
  proc_add: {
    path: '/proc_add',
    name: 'procAdd',
    component: () => import('@/views/OperateManagement/ReadManage/TabChild/ProcAdd.vue'),
    meta: {
      title: '新增工序分配'
    }
  },
  proc_edit: {
    path: '/proc_edit',
    name: 'proc_edit',
    component: () => import('@/views/OperateManagement/ReadManage/TabChild/ProcEdit.vue'),
    meta: {
      title: '修改工序分配'
    }
  }
};
export default operRouter;
