const mdtkRouter = {
  list: {
    path: '/mdtk_list',
    name: 'MdtkList',
    component: () => import('@/views/StockManagement/MdtkManage/MdtkList.vue'),
    meta: {
      title: '模具总库存表 列表'
    }
  },
  push: {
    path: '/mdtk_push_list_9001',
    name: 'MdtkPush9001',
    component: () => import('@/views/StockManagement/MdtkManage/MdtkPush9001.vue'),
    meta: {
      title: '模具库存提醒'
    }
  }
};
export default mdtkRouter;
